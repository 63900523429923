import React from 'react';
import { BallTriangle } from 'react-loader-spinner';

import './style.scss';

const Loading = ({ width = '70', height = '70' }) => {
  return (
    <div className="custom-loading-container">
      <BallTriangle
        height={height}
        width={width}
        radius={5}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle=""
        visible={true}
      />
    </div>
  );
};

export default Loading;
