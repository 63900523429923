import "./style.scss";
import {Link} from "react-router-dom";

function ButtonSubmit() {
  return (

      <Link to="/support-feedback/tickets" className="buttons__btn buttons__btn-submit animation-scale">
        <div className="buttons__btn-submit-group">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.5 7.5V18.6318C19.5 19.2705 18.7526 19.6169 18.2652 19.2041L15.3333 16.7206H14.5H6.75C5.50736 16.7206 4.5 15.7132 4.5 14.4706V10.9853V7.5C4.5 6.25736 5.50736 5.25 6.75 5.25H17.25C18.4926 5.25 19.5 6.25736 19.5 7.5Z"
              stroke="white"
              strokeWidth="1.5"
            />
            <path
              d="M11.9302 11.75V11.6243C11.9302 11.115 12.2394 10.6552 12.7151 10.4572C13.1908 10.2592 13.5 9.79571 13.5 9.28642C13.5 8.57905 12.9187 8 12.2015 8H11.9394C11.1444 8 10.5 8.63567 10.5 9.4198"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 12.75C12.5523 12.75 13 13.1511 13 13.646V13.854C13 14.3489 12.5523 14.75 12 14.75C11.4477 14.75 11 14.3489 11 13.854V13.646C11 13.1511 11.4477 12.75 12 12.75Z"
              fill="white"
            />
          </svg>
          Add new ticket
        </div>
      </Link>
  );
}

export default ButtonSubmit;
