import "./style.scss";
import {Link} from "react-router-dom";

function ButtonTickets() {
  return (

      <Link to="/support-feedback/tickets" className="buttons__btn buttons__btn-tickets animation-scale" >

        <div className="buttons__btn-tickets-group">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
              stroke="black"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 11.375C7 8.95875 9.18433 7 11.8788 7C13.9004 7 15.6348 8.10253 16.375 9.67361M17 12.625C17 15.0412 14.8157 17 12.1212 17C10.0996 17 8.36523 15.8975 7.625 14.3264M15.0103 9.77344L16.8269 9.77349V8.10068M8.91564 14.2266L7.09901 14.2266L7.09901 15.8994"
              stroke="black"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21.5 6C21.5 7.933 19.933 9.5 18 9.5C16.067 9.5 14.5 7.933 14.5 6C14.5 4.067 16.067 2.5 18 2.5C19.933 2.5 21.5 4.067 21.5 6Z"
              stroke="white"
              strokeWidth="2"
            />
            <circle cx="18" cy="6" r="2.5" fill="#FF5811" />
          </svg>
          All my tickets
        </div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4817 5.45871L6.75 11.8164"
            stroke="black"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5 5.45898L6 5.45898"
            stroke="black"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.5 5.45768L13.5 12.541"
            stroke="black"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

      </Link>
  );
}

export default ButtonTickets;
