
import './style.scss';
import { Logo } from "../../assets/hero";

const AnimationModal = () => {
   return (
      <div className="animation__container">
         <div className="animation__logo">
            <Logo />
         </div>
         <p className="animation__text">
            Utility token backed&nbsp;up by&nbsp;two legitimate projects
         </p>
         <p className="animation__subtext">for Life & for Work</p>
      </div>
   );
};

export default AnimationModal;
