const SvgStatusActive = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11.375C7 8.95875 9.18433 7 11.8788 7C13.9004 7 15.6348 8.10253 16.375 9.67361M17 12.625C17 15.0412 14.8157 17 12.1212 17C10.0996 17 8.36523 15.8975 7.625 14.3264M15.0103 9.77344L16.8269 9.77349V8.10068M8.91564 14.2266L7.09901 14.2266L7.09901 15.8994"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgStatusActive;
