import React, { useState, useRef } from 'react';
import useOutsideClick from '../../../hooks/useOutsideClick';
import './style.scss';

const topics = [
  {
    name: 'Tips & Tricks',
  },
  {
    name: 'Sign Up',
  },
  {
    name: 'Intro',
  },
  {
    name: 'Best Practices',
  },
  {
    name: 'Login and Password',
  },
  {
    name: 'Manage Emails',
  },
  {
    name: 'Inbox Permissions',
  },
  {
    name: 'What are the fees?',
  },
  {
    name: 'Other...',
  },
];

const SelectTicketTopic = ({ setTicketTitle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeTopic, setActiveTopic] = useState('select a help topic');

  const ref = useRef(null);
  useOutsideClick(ref, () => setIsOpen(false));

  return (
    <div ref={ref} className="selectTicketTopic__container">
      <div onClick={() => setIsOpen(true)} className="selectTicketTopic__body">
        <h4
          className="selectTicketTopic__title"
          style={{ color: activeTopic === 'select a help topic' ? '#7d99a0' : '#000' }}>
          {activeTopic}
        </h4>
        <span className="selectTicketTopic__svg">
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.02347 10L5 5.56106L1.02347 1"
              stroke="black"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </span>
      </div>
      {isOpen && (
        <div className="selectTicketTopic__topics-container">
          <ul className="selectTicketTopic__topics-list">
            {topics.map((topic, index) => (
              <li
                onClick={() => {
                  setActiveTopic(topic.name);
                  setTicketTitle(topic.name);
                  setIsOpen(false);
                }}
                className="selectTicketTopic__topics-item"
                key={index}>
                {topic.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectTicketTopic;
