import React from 'react';
import './style.scss';

const AttachFileButton = () => {
  return (
    <div className="support-attachBtn__container">
      <label htmlFor="attach-support-file" className="support-attachBtn__label animation-scale">
        <span className="support-attachBtn__label-svg">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.9628 20.2132L19.6503 12.5351C19.7924 12.3941 19.8731 12.2028 19.8748 12.0026C19.8766 11.8025 19.7992 11.6098 19.6596 11.4663M11.9628 20.2132L19.6596 11.4663M11.9628 20.2132C10.9782 21.1978 9.64272 21.751 8.25027 21.751C6.85781 21.751 5.52238 21.1978 4.53777 20.2132C3.55315 19.2286 3 17.8932 3 16.5007C3 15.1083 3.55315 13.7728 4.53777 12.7882L11.9628 20.2132ZM19.6596 11.4663L19.6149 11.5099C19.615 11.5101 19.6151 11.5102 19.6153 11.5104C19.6154 11.5105 19.6155 11.5106 19.6157 11.5108L19.6596 11.4663ZM7.43778 16.5019L7.43775 16.5006C7.43794 16.3928 7.45959 16.286 7.50144 16.1867C7.54321 16.0875 7.60427 15.9976 7.68107 15.9222C7.68122 15.922 7.68137 15.9219 7.68152 15.9217L7.43778 16.5019ZM7.43778 16.5019C7.44076 16.6616 7.4902 16.8169 7.58007 16.949C7.66994 17.081 7.79633 17.184 7.9438 17.2454C8.09127 17.3067 8.25342 17.3238 8.41042 17.2945C8.56743 17.2651 8.71249 17.1907 8.82786 17.0802L8.82788 17.0803M7.43778 16.5019L8.82788 17.0803M8.82788 17.0803L8.82917 17.079M8.82788 17.0803L8.82917 17.079M8.82917 17.079L18.1285 7.639M8.82917 17.079L18.1285 7.639M18.1285 7.639C18.1286 7.63888 18.1287 7.63876 18.1289 7.63864M18.1285 7.639L18.1289 7.63864M18.1289 7.63864C18.3459 7.4248 18.5182 7.16985 18.6356 6.8887C18.7531 6.60743 18.8133 6.30557 18.8128 6.00076M18.1289 7.63864L18.8128 6.00076M18.8128 6.00076C18.8128 6.0008 18.8128 6.00084 18.8128 6.00087L18.7503 6.00072L18.8128 6.00061C18.8128 6.00066 18.8128 6.00071 18.8128 6.00076ZM18.635 11.5107L18.6351 11.5105L18.5909 11.4663L18.6349 11.5108C18.6349 11.5107 18.6349 11.5107 18.635 11.5107ZM10.9487 19.1969L10.9034 19.1538L10.9476 19.198L10.9481 19.1975C10.9483 19.1973 10.9485 19.1971 10.9487 19.1969ZM5.56274 13.803L5.56199 13.8037L5.60652 13.8476L5.56351 13.8022C5.56325 13.8025 5.563 13.8027 5.56274 13.803Z"
              fill="black"
              stroke="black"
              strokeWidth="0.125"
            />
          </svg>
        </span>
        <span className="support-attachBtn__label-text">attach file</span>
      </label>
      <div className="support-attachBtn__types-container">
        <div className="support-attachBtn__types">.jpg, .gif, .jpeg, .png, .pdf, .txt</div>
        <div className="support-attachBtn__maxSize">max 5Mb</div>
      </div>

      <input
        style={{ opacity: 0, position: 'absolute', zIndex: -1 }}
        type="file"
        name="attach-support-file"
        id="attach-support-file"
      />
    </div>
  );
};

export default AttachFileButton;
