const baseHeaders = new Headers({
  accept: 'application/json',
  authorization: 'Bearer dG9rOmU0MmQzMGYxXzUzZTZfNDAxMV84MDliX2JjMjlmY2IxNjkwMDoxOjA=',
  'content-type': 'application/json',
  'Intercom-Version': '2.9',
});

export const getTickets = async () => {
  var myHeaders = new Headers(baseHeaders);

  var raw = JSON.stringify({
    query: {
      field: 'contact_ids',
      operator: '=',
      value: '64660012397aba3e73052ae5',
      // value: '6464f4c673920d24bd5420c1',
    },
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  return fetch('https://swgt.prospect.is/conversations/search', requestOptions)
    .then((response) => response.json())
    .then((result) => result.conversations)
    .catch((error) => console.log('error', error));
};

export const createTicket = async (ticketObj) => {
  const headers = new Headers(baseHeaders);

  const raw = JSON.stringify({
    query: {
      field: 'email',
      operator: '=',
      value: 'john@gmail.com',
    },
  });

  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: raw,
    redirect: 'follow',
  };

  fetch('https://swgt.prospect.is/contacts/search', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      console.log(result.data[0].id);

      var raw = JSON.stringify({
        contacts: [
          {
            // id: result.data[0].id,
            id: '64660012397aba3e73052ae5',
          },
        ],
        ticket_attributes: {
          _default_title_: ticketObj.title,
          _default_description_: ticketObj.description,
        },
        ticket_type_id: '1',
      });

      var requestOptions = {
        method: 'POST',
        headers: headers,
        body: raw,
        redirect: 'follow',
      };

      fetch('https://swgt.prospect.is/tickets', requestOptions)
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log('error', error));
    })
    .catch((error) => console.log('error', error));
};
