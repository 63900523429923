export const Menu = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 12"
    width="24px"
    height="24px"
    {...props}
  >
    <path
      fill="currentColor"
      d="M3.962 1.984a1.983 1.983 0 0 1-1.98 1.984A1.983 1.983 0 0 1 0 1.984C0 .888.887 0 1.981 0s1.981.888 1.981 1.984Zm0 8.032A1.983 1.983 0 0 1 1.982 12 1.983 1.983 0 0 1 0 10.016C0 8.92.887 8.032 1.981 8.032s1.981.888 1.981 1.984Zm8.02-8.032a1.983 1.983 0 0 1-1.981 1.984A1.983 1.983 0 0 1 8.02 1.984C8.02.888 8.907 0 10 0s1.981.888 1.981 1.984Zm0 8.032A1.983 1.983 0 0 1 10.001 12a1.983 1.983 0 0 1-1.981-1.984c0-1.096.887-1.984 1.981-1.984s1.981.888 1.981 1.984ZM20 1.984a1.983 1.983 0 0 1-1.981 1.984 1.983 1.983 0 0 1-1.981-1.984 1.982 1.982 0 1 1 3.962 0Zm0 8.032A1.983 1.983 0 0 1 18.019 12a1.983 1.983 0 0 1-1.981-1.984 1.982 1.982 0 1 1 3.962 0Z"
    ></path>
  </svg>
);


