import "./style.scss";
import HeroImg from "../../assets/hero/hero.png";
import { Bullet } from "../../assets/hero";
import Countdown from "../../components/Countdown";

const hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <div className="hero__container">
          <img
            className="img hero__img"
            alt="hero"
            src={HeroImg}
            decoding="async"
            data-nimg="fil"
            loading="lazy"
          />
        </div>
        <div className="hero__text text-hero">
          <div className="text-hero__description">
            <h1>
              Utility token backed up by two legitimate projects
              <b> for Life & for Work</b>
            </h1>
            <div className="text-hero__description-bullets">
              <div>
                <Bullet />
              </div>
              <span className="bodyUppercase">
                UNIQUE REVOLUTIONARY TRANSPORT SYSTEM BASED ON BLOCKCHAIN WITH
                CRYPTO PAYMENTS IN SWGT
              </span>
            </div>
            <div className="text-hero__description-bullets">
              <div>
                <Bullet />
              </div>
              <span className="bodyUppercase">
                ZERO COMMISSIONS DECENTRALIZED WORK MARKETPLACE
              </span>
            </div>
          </div>
          <div className="countdown-form">
            <Countdown
                upperText={"Token sale starts in:"}
                bottomText={"JOIN PRE-SALE NOW for a chance to receive SWGT tokens for free"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default hero;
