import React from 'react';
import SupportForm from '../components/SupportForm';

const FormPage = () => {
  return (
    <div className="support-feedback-wrapper">
      <div className="support-feedback-container">
        <SupportForm />
      </div>
    </div>
  );
};

export default FormPage;
