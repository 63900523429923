const SvgStatusActive = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.47487 7.52513L9.94975 10M9.94975 10L12.4246 12.4749M9.94975 10L12.4246 7.52513M9.94975 10L7.47487 12.4749M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
      stroke="#7D99A0"
      strokeWidth="1.6"
      strokeLnecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgStatusActive;
