import {useState} from 'react'

import './style.scss'

const Checkbox = (props) => {
  const [isChecked, setIsChecked] = useState(props.isChecked || false)

  return (
    <div 
      onClick={() => setIsChecked(prev => !prev)}
      className={`custom-checkbox ${isChecked ? 'custom-checkbox--active' : ''}`}
    >
      {isChecked && <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2.25L5.07407 6L12 1" stroke="white" strokeWidth="2" strokeLinecap="round"/>
        </svg>}
    </div>
  )
}

export default Checkbox