export const Login = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="24" height="24" rx="12" fill="#CBF4FF" />
    <circle
      cx="12"
      cy="10.0008"
      r="3"
      stroke="black"
      strokeWidth="1.1875"
      strokeLinecap="round"
    />
    <circle
      cx="12.0007"
      cy="12.0007"
      r="8.1687"
      stroke="black"
      strokeWidth="1.1875"
    />
    <path
      d="M15.999 16.0008C15.7141 15.1257 15.1753 14.3659 14.458 13.828C13.7407 13.29 12.8809 13.0008 11.999 13.0008C11.1171 13.0008 10.2573 13.29 9.54006 13.828C8.82278 14.3659 8.28395 15.1257 7.99902 16.0008"
      stroke="black"
      strokeWidth="1.1875"
      strokeLinecap="round"
    />
  </svg>
);

