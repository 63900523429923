import { useEffect, useState } from "react";
import {fetchArticlesData} from "../services/article-api";


const useFetchArticles = (collectionId) => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            const articlesData = await fetchArticlesData();
            const filteredArticles = articlesData.filter(
                (article) => article.parent_id && article.parent_id.toString() === collectionId
            );
            setArticles(filteredArticles);
        };

        fetchArticles();
    }, [collectionId]);

    return articles;
};
export default useFetchArticles;