import { useRef, useState } from 'react';

import { createTicket } from '../../services/tickets-api';
import useOutsideClick from '../../hooks/useOutsideClick';
import SelectTicketTopic from '../UI/SelectTicketTopic';
import AttachFileButton from '../UI/AttachFileButton/AttachFileButton';
import SubmitButton from '../UI/SubmitButton';

import './style.scss';

const NewTicketModal = ({ handleModalClose, setUpdateFlag }) => {
  const [ticketTitle, setTicketTitle] = useState('');
  const [ticketDescription, setTicketDescription] = useState('');

  const ref = useRef(null);
  useOutsideClick(ref, handleModalClose);

  const onCreateTicket = (e) => {
    e.preventDefault();

    if (!ticketTitle) {
      alert('Choose your topic');
      return;
    }
    if (!ticketDescription) {
      alert('Please describe your problem');
      return;
    }

    const ticketObj = {
      title: ticketTitle,
      description: ticketDescription,
    };

    createTicket(ticketObj)
      .then(() => {
        handleModalClose();

        setTimeout(() => {
          setUpdateFlag();
        }, 3000);
      })
      .catch((error) => {
        console.log('Error:', error);
      });
  };

  return (
    <div className="ticketModal__wrapper">
      <div className="ticketModal__container">
        <div ref={ref} className="ticketModal__body" onClick={(e) => e.stopPropagation()}>
          <h3 className="ticketModal__title">New ticket</h3>

          <div onClick={() => handleModalClose()} className="ticketModal__close">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g>
                <circle cx="12" cy="12" r="11" fill="black" />
                <path
                  d="M9.47656 9.52344L11.9514 11.9983M11.9514 11.9983L14.4263 14.4732M11.9514 11.9983L14.4263 9.52344M11.9514 11.9983L9.47656 14.4732"
                  stroke="white"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath>
                  <rect width="24" height="24" rx="12" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <form className="ticketModal__form" onSubmit={(e) => onCreateTicket(e)}>
            <SelectTicketTopic setTicketTitle={(title) => setTicketTitle(title)} />

            <textarea
              className="ticketModal__textarea"
              placeholder="Your message here"
              name="ticketModalTextarea"
              value={ticketDescription}
              onChange={(e) => setTicketDescription(e.target.value)}
            />

            <div className="ticketModal__btnContainer">
              <AttachFileButton />
              <SubmitButton />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewTicketModal;
