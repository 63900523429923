import './style.scss';
import Sidebar from "./Sidebar";
import ButtonSubmit from "../components/ButtonSubmit";
import ButtonTickets from "../components/ButtonTickets";
import Tips from "./Tips";


const SupportInfoPage = () => {
  return (
      <div className="info-container">
        <div className="info-tools-container">
          <div className="sidebar-container">
            <div className="sidebar-items">
              <Sidebar/>
            </div>
          </div>
          <div className="buttons-info__container">
            <ButtonSubmit />
            <ButtonTickets />
          </div>
        </div>
        <div className="page__container">
          <Tips />
        </div>
      </div>
  );
};
export default SupportInfoPage;