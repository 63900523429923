import { useState, useEffect } from 'react';
import TicketItem from './TicketItem';
import Checkbox from '../UI/Checkbox';

import { getTickets } from '../../services/tickets-api';
import Loading from '../../../components/Loading';

const Tickets = ({ activeTicket, setActiveTicket, handleModalOpen, updateFlag }) => {
  const [conversationsItems, setConversationsItems] = useState([]);
  const [isConversationsLoading, setIsConversationsLoading] = useState(true);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    getTickets()
      .then((conversations) => {
        setConversationsItems(conversations);
      })
      .catch((error) => {
        console.log('Error:', error);
      })
      .finally(() => setIsConversationsLoading(false));
  }, [updateFlag]);

  return (
    <div className="tickets__container">
      <div className="tickets__top-container">
        <button onClick={handleModalOpen} className="tickets__top-addBtn animation-scale">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M19.5 7.5V18.6318C19.5 19.2705 18.7526 19.6169 18.2652 19.2041L15.3333 16.7206H14.5H6.75C5.50736 16.7206 4.5 15.7132 4.5 14.4706V10.9853V7.5C4.5 6.25736 5.50736 5.25 6.75 5.25H17.25C18.4926 5.25 19.5 6.25736 19.5 7.5Z"
                stroke="white"
                strokeWidth="1.5"
              />
              <path
                d="M11.9302 11.75V11.6243C11.9302 11.115 12.2394 10.6552 12.7151 10.4572C13.1908 10.2592 13.5 9.79571 13.5 9.28642C13.5 8.57905 12.9187 8 12.2015 8H11.9394C11.1444 8 10.5 8.63567 10.5 9.4198"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 12.75C12.5523 12.75 13 13.1511 13 13.646V13.854C13 14.3489 12.5523 14.75 12 14.75C11.4477 14.75 11 14.3489 11 13.854V13.646C11 13.1511 11.4477 12.75 12 12.75Z"
                fill="white"
              />
            </svg>
          </span>
          <span className="tickets__top-addBtn-text">Add new ticket</span>
        </button>
        <div className="tickets__top-searchInput-container">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.5721 15.3912L18.9995 18.6496M17.0837 11.3934C17.0837 14.7303 14.3787 17.4353 11.0419 17.4353C7.70504 17.4353 5 14.7303 5 11.3934C5 8.0566 7.70504 5.35156 11.0419 5.35156C14.3787 5.35156 17.0837 8.0566 17.0837 11.3934Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </span>
          <input
            type="text"
            className="tickets__top-searchInput"
            placeholder="Enter here to search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </div>

      <div className="tickets__body">
        <div className="tickets__body-top">
          <div className="tickets__filter">
            <div className="tickets__filter-body" onClick={() => setIsFiltersOpen((prev) => !prev)}>
              <span className="tickets__filter-text">filter</span>
              <span>
                <svg
                  width="7"
                  height="11"
                  viewBox="0 0 7 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.02347 10L5 5.56106L1.02347 1"
                    stroke="black"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            </div>
            {isFiltersOpen && (
              <div className="tickets__filter-list">
                <div className="tickets__filter-item">
                  <Checkbox isChecked={true} />
                  <span>in progress</span>
                </div>
                <div className="tickets__filter-item">
                  <Checkbox isChecked={true} />
                  <span>awaiting your reply</span>
                </div>
                <div className="tickets__filter-item">
                  <Checkbox isChecked={true} />
                  <span>Solved</span>
                </div>
              </div>
            )}
          </div>
          <div className="tickets__edit">edit</div>
        </div>

        {isConversationsLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <>
            {conversationsItems.length ? (
              <ul className="tickets__list">
                {conversationsItems
                  .filter(
                    (item) => item.ticket,
                    // &&
                    // item.ticket?.custom_attributes?._default_title_
                    //   ?.toLowerCase()
                    //   .includes(searchValue.toLowerCase()),
                  )
                  .map((item) => (
                    <TicketItem
                      activeTabId={activeTicket?.id}
                      setActiveTicket={(ticket) => setActiveTicket(ticket)}
                      key={item.id}
                      item={item}
                    />
                  ))}
              </ul>
            ) : (
              <>{!isConversationsLoading && <div>There is no tickets:(</div>}</>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Tickets;
