import './style.scss';

interface ButtonProps {
  title?: string;
  height?:string;
  width?:string;
  maxWidth?: string;
  background?: string;
}

const Button: React.FC<ButtonProps> = ({ title,height, width, maxWidth, background }) => {
  const buttonStyle = {
    width: width || '',
    height: height || '',
    maxWidth: maxWidth || '',
    background: background || '#006C9A',
  };
  return (
    <>
      <button className="btn-input" style={buttonStyle}>
        {title ? (
          <p className="btn-title"> {title}</p>
        ) : (
          <svg
            width="20"
            height="8"
            viewBox="0 0 20 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5385 0.464437L19.7205 3.64642C19.9158 3.84168 19.9158 4.15826 19.7205 4.35352L16.5385 7.5355C16.3433 7.73077 16.0267 7.73077 15.8314 7.5355C15.6361 7.34024 15.6361 7.02366 15.8314 6.8284L18.1598 4.49997L0.132813 4.49997L0.132813 3.49997L18.1598 3.49997L15.8314 1.17154C15.6361 0.976282 15.6361 0.659699 15.8314 0.464437C16.0267 0.269175 16.3433 0.269175 16.5385 0.464437Z"
              fill="white"
            />
          </svg>
        )}
      </button>
    </>
  );
};

export default Button;
