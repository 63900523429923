import React, { useState } from "react";
import "./style.scss";
import {NavLink, useLocation} from "react-router-dom";

const Tabs = () => {
  const [activeTab, setActiveTab] = useState("Faq");
  const location = useLocation();
  const isFaqTab = location.pathname === "/Support/";
  const isFeedbackTab = location.pathname.includes("/Support-feedback/");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
      <div className="tabs__container">
        <div>
          <div className="tabs__container-caption">Helpcenter</div>
          <div className="tabs__container-pagination">
            .../ F.A.Q. &nbsp;
            <div style={{ color: "black" }}>{location.pathname}</div>
          </div>
        </div>

        <div className="tabs__links">
          <NavLink
              to="/support/"
              className={isFaqTab ? "active" : ""}
              onClick={() => handleTabClick("Faq")}
          >
            F.A.Q.
          </NavLink>
          <NavLink
              to="/support-feedback/tickets"
              className={isFeedbackTab ? "active" : ""}
              onClick={() => handleTabClick("feedback")}
          >
            TICKETS
          </NavLink>
        </div>
      </div>
  );
};

export default Tabs;