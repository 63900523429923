import React from "react";
import "./style.scss"
import { useLocation } from "react-router-dom";
import useFetchArticles from "../../hooks/useFetchArticles";


const Tips = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const collectionId = pathParts[pathParts.length - 1];
  const articles = useFetchArticles(collectionId);

  return (
    <div className="tips__container">
      {articles.map((article) => (
        <div key={article.id} id={article.id}>
          <h4 className="tips__title">{article.title}</h4>
          <div
            className="tips__content"
            dangerouslySetInnerHTML={{ __html: article.body }}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Tips;
