import { useNavigate } from 'react-router-dom';

import Checkbox from '../UI/Checkbox';
import SvgStatusActive from '../../../assets/svg/SvgStatusActive';
import SvgStatusInactive from '../../../assets/svg/SvgStatusInactive';

import './style.scss';

export const getDate = (mms) => {
  const date = new Date(mms * 1000);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const getStatus = (state, isRead) => {
  if (state === 'close') return { text: 'solved', bgColor: '#D2E2E7' };

  if (isRead) {
    return { text: 'awaiting your reply', bgColor: '#D7FFDD' };
  } else {
    return { text: 'in progress', bgColor: '#CBF4FF' };
  }
};

const TicketItem = ({ item }) => {
  const navigate = useNavigate();
  const { id, ticket, created_at, state, read } = item;

  const title = ticket.custom_attributes._default_title_.value || 'Def title';
  const statusObj = getStatus(state, read);

  return (
    <li
      onClick={() => navigate(`/support-feedback/tickets/${id}`)}
      className="tickets__item item-tickets">
      <div className="item-tickets__body">
        <div className="item-tickets__checkModal">
          <svg
            width="7"
            height="11"
            viewBox="0 0 7 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.02347 10L5 5.56106L1.02347 1"
              stroke="black"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </div>

        <div className="item-tickets__status">
          <span className="item-tickets__status-icon">
            {state === 'close' ? <SvgStatusInactive /> : <SvgStatusActive />}
          </span>
          <div style={{ background: statusObj.bgColor }} className="item-tickets__status-text">
            {statusObj.text}
          </div>
        </div>

        <div className="item-tickets__title">
          <h4
          // style={{ color: !disabled ? '#000000' : '#7D99A0' }}
          >
            {title}
          </h4>
        </div>

        <div className="item-tickets__info">
          <span
            className="item-tickets__number"
            // style={{ color: !disabled ? '#000000' : '#7D99A0' }}
          >
            №{id}
          </span>
          <span className="item-tickets__created">{getDate(created_at)}</span>
        </div>

        <div onClick={(e) => e.stopPropagation()} className="item-tickets__edit">
          <Checkbox />
        </div>
      </div>
    </li>
  );
};

export default TicketItem;
