import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './style.scss';
import { Outlet } from 'react-router';
import Tabs from './tabs';

const HelpCenterLayout = () => {
  const root = useRef<HTMLDivElement>(null);
  const [hideTabs, setHideTabs] = useState(false);
  const location = useLocation();

  //Скрытие табов при скролле вниз
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !hideTabs) {
        setHideTabs(true);
      } else if (window.scrollY === 0 && hideTabs) {
        setHideTabs(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hideTabs]);

  const shouldDisplayTabs = location.pathname.includes('/Support/');

  return (
    <div ref={root} className="App">
      <div className="mainPage">
        <div className="mainPage__container">
          <div className={`mainPage__tabs ${shouldDisplayTabs && hideTabs ? 'hidden' : ''}`}>
            <Tabs />
          </div>
          <div className="content">
            {/* Контент Здесь */}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenterLayout;
