export const Bullet = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="16" rx="8" fill="black" />
    <path
      d="M13.0999 8.77999H8.8399V13.1H7.1399V8.77999H2.8999V7.24H7.1399V2.89999H8.8399V7.24H13.0999V8.77999Z"
      fill="white"
    />
  </svg>
);

