import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { TicketPage, FormPage } from '../pages/Support/supportFeedbackPage';
import SupportInfoPage from '../pages/Support/supportInfoPage';
import { MainPage } from "../pages/Support/supportMainPage";
import MainLayout from "../layouts/MainLayout";
import HelpCenterLayout from "../layouts/HelpCenterLayout";
import EmbeddedDocument from "./index";



const AppRouting = () => {
  return (
      <BrowserRouter>
        <Routes>
          <Route index element={<MainLayout />} />
          <Route element={<HelpCenterLayout />}>
            <Route path="/whitepaper" element={<EmbeddedDocument />} />
            <Route path="/support" element={<MainPage />} />
            <Route path="/support/:id" element={<SupportInfoPage />} />
            <Route path="/support-feedback/tickets" element={<TicketPage />} />
            <Route path="/support-feedback/tickets/:id" element={<FormPage />} />
          </Route>
          {/* page not found */}
          <Route path="*" element={<div>Страница не найдена!</div>} />
        </Routes>
      </BrowserRouter>
  );
};

export default AppRouting;