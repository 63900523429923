import React from 'react';
import './style.scss';

const SubmitButton = () => {
  return (
    <button type="submit" className="support-submitBtn animation-scale">
      <svg
        width="24"
        height="11"
        viewBox="0 0 24 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0707 0.805241L23.2416 4.97613C23.4976 5.23208 23.4976 5.64705 23.2416 5.90299L19.0707 10.0739C18.8148 10.3298 18.3998 10.3298 18.1439 10.0739C17.8879 9.81794 17.8879 9.40297 18.1439 9.14702L21.1959 6.09495L1.2218 6.09496C0.859836 6.09496 0.566406 5.80153 0.566406 5.43957C0.566406 5.0776 0.859836 4.78417 1.2218 4.78417L21.1959 4.78417L18.1439 1.73211C17.8879 1.47616 17.8879 1.06119 18.1439 0.805241C18.3998 0.549295 18.8148 0.549295 19.0707 0.805241Z"
          fill="white"
        />
      </svg>
    </button>
  );
};

export default SubmitButton;
