import { useState } from "react";
import "./style.scss";
import { Close, Login, Logo, Menu } from "../../assets/hero";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const navLinks = [
    {
      title: "Solutions for Life",
      url: "#solutions-for-life",
    },
    {
      title: "Solutions for Work",
      url: "#solutions-for-work",
    },
    {
      title: "SWGT Token Ecosystem",
      url: "#token-ecosystem",
    },
    {
      title: "SWGT Token Sale ",
      url: "#token-sale",
    },
    {
      title: "Company",
      url: "#company",
    },
    {
      title: "Contact",
      url: "#contact",
    },
  ];
  const handleNavLinkClick = (event) => {
    event.preventDefault();

    const linkElement = event.currentTarget;

    const target = document.querySelector(linkElement.getAttribute('href'));
    if (target) {
      const offset = 70;
      const topOffset = target.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: topOffset,
        behavior: 'smooth',
      });
    }
  };
  return (
      <header className="header">
        <div className="header__container">
          <a href="http://swgt.swg.io/">
            <div className="header__logo">
              <Logo />
            </div>
          </a>
          <nav className="nav">
            <div className="nav__wrapper">
              {navLinks.map((link, index) => (
                  <a key={index} className="nav__link" href={link.url} onClick={handleNavLinkClick}>
                    <span className="menuRegular">{link.title}</span>
                  </a>
              ))}
            </div>
            <button className="nav__burger" onClick={toggleMenu}>
              <span className="nav__burger-icon">{isOpen ? <Close /> : <Menu />}</span>
            </button>
          </nav>

          {isOpen && (
              <div className="navbar">
                {navLinks.map((link, index) => (
                    <a key={index} className="navbar__link" href={link.url}>
                      {link.title}
                    </a>
                ))}
              </div>
          )}

          <a className="nav__login" href="https://account.swg.io/auth/login">
            <span>Log in</span>
            <Login />
          </a>
        </div>
      </header>
  );
};

export default Header;