export const fetchArticlesData = async () => {
    try {
        const response = await fetch("https://swgt.prospect.is/articles");
        const result = await response.json();
        return result.data;
    } catch (error) {
        console.log("error", error);
        return [];
    }
};

export const fetchCollectionsData = async () => {
    const options = {
        method: "GET",
        headers: {
            accept: "application/json",
            "Intercom-Version": "2.9",
            authorization:
                "Bearer dG9rOmU0MmQzMGYxXzUzZTZfNDAxMV84MDliX2JjMjlmY2IxNjkwMDoxOjA='",
        },
    };

    try {
        const response = await fetch(
            "https://swgt.prospect.is/help_center/collections",
            options
        );
        const result = await response.json();
        return result.data; // Вернуть весь массив result.data
    } catch (error) {
        console.error(error);
        return [];
    }
};