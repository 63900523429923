import { useState } from 'react';
import Tickets from '../components/Tickets';
import NewTicketModal from '../components/NewTicketModal';

import '../style.scss';

const TicketPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(0);

  return (
    <div className="" style={{ position: 'relative', height: '100vh' }}>
      <div className="support-feedback-container">
        <Tickets handleModalOpen={() => setIsModalOpen(true)} updateFlag={updateFlag} />
      </div>

      {isModalOpen && (
        <NewTicketModal
          handleModalClose={() => setIsModalOpen(false)}
          setUpdateFlag={() => setUpdateFlag((prev) => prev + 1)}
        />
      )}
    </div>
  );
};

export default TicketPage;
