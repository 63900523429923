export const Close = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 30 18"
    width="24px"
    height="24px"

    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="3"
      d="m3.285 2.695 23.383 13.098m-23.383 0 23.383-13.5"
    ></path>
  </svg>
);

