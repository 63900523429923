import gsap from 'gsap';
import { useEffect, useLayoutEffect, useRef } from 'react';
import Animation from '../../animation';
import AnimationModal from '../../components/AnimationModal';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

import {
  Hero,
  Transportation,
  Video,
  Capex,
  Solutions,
  AirDrop,
  Faq,
  Gallery,
  Commission,
  Blockchain,
} from '../../pages';

const MainLayout = () => {
  const isAnimated = !!localStorage.getItem('is-show-introduction');
  const root = useRef<HTMLDivElement>(null);
  // timeline
  const tl = useRef<any>(null);

  useEffect(() => {
    //@ts-ignore
    window.Intercom('shutdown');
  }, []);

  useLayoutEffect(() => {
    if (isAnimated) return;

    let ctx = gsap.context(() => {
      tl.current = gsap
        .timeline()
        .to('.animation__container', { opacity: 1, duration: 1.5 })
        .from('.animation__logo', { opacity: 0, duration: 1.5 })
        .from('.animation__text', { scale: 1.4, opacity: 0, duration: 1 }, '-=1')
        .from('.animation__subtext', { scale: 1.4, opacity: 0, duration: 1.3 })
        .to('.animation__container', { opacity: 0, display: 'none', duration: 1.2 })
        .from('.header', { opacity: 0, y: 60, duration: 0.7 })
        .from('.hero__container', { opacity: 0, y: 60, duration: 0.7 })
        .from('.hero__text', { opacity: 0, y: 60, duration: 0.7 });
    }, root);

    localStorage.setItem('is-show-introduction', 'true');

    return () => ctx.revert();
  }, []);
  return (
    <div ref={root} className="App">
      <div className="container__pages">
        <Header />
        <Hero />
        {/* <Transportation />
        <Animation />
        <Capex />
        <Video />
        <Solutions />
        <Blockchain />
        <Commission />
        <Gallery />
        <AirDrop />
        <Faq />
        <Footer /> */}
      </div>
      {!isAnimated && <AnimationModal />}
    </div>
  );
};

export default MainLayout;
