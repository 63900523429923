import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import SupportFormMessageList from './SupportFormMessageList';
import AttachFileButton from '../UI/AttachFileButton/AttachFileButton';
import SubmitButton from '../UI/SubmitButton';

import { getConversation, sendConversationMessage } from '../../services/conversation-api';
import Loading from '../../../components/Loading';
import { getDate } from '../Tickets/TicketItem';

import './style.scss';

const SupportForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const conversationId = location.pathname.split('/').at(-1) || null;
  const [conversation, setConversation] = useState();
  const [chatItems, setChatItems] = useState([]);
  const [isConversationsLoading, setIsConversationsLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (conversationId) {
      getConversation(conversationId)
        .then((conversation) => {
          console.log(conversation.conversation_parts.conversation_parts);

          setConversation(conversation);
          setChatItems(conversation.conversation_parts.conversation_parts);
        })
        .catch((error) => {
          console.log('Error:', error);
        })
        .finally(() => setIsConversationsLoading(false));
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    if (!conversationId) {
      alert('Error');
      return;
    }
    if (!message) {
      alert('Write something');
      return;
    }

    const messageObj = {
      created_at: Math.floor(new Date().getTime() / 1000),
      body: `<p>${message}</p>`,
      author: {
        type: 'user',
      },
    };

    setChatItems((prev) => [...prev, messageObj]);
    setMessage('');

    sendConversationMessage(conversationId, message).catch((error) => console.log(error));
  };

  if (isConversationsLoading) {
    return (
      <div className="supportForm__container supportForm__container--loading">
        <Loading />
      </div>
    );
  }

  return (
    <div className="supportForm__container">
      <div className="supportForm__infoBlock">
        <h4 className="supportForm__infoBlock-title">
          {conversation.ticket.custom_attributes._default_title_.value || 'Default'}
        </h4>
        <div className="supportForm__infoBlock-right">
          <span className="supportForm__infoBlock-number">№{conversation.ticket.id}</span>
          <span className="supportForm__infoBlock-created">
            created {getDate(conversation.created_at)}
          </span>
        </div>
      </div>

      {isConversationsLoading ? (
        <div>
          <Loading />
        </div>
      ) : (
        <SupportFormMessageList chatItems={chatItems} />
      )}

      <form onSubmit={(e) => onSubmit(e)}>
        <div className="supportForm__textarea">
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            name="supportForm__textarea"
            placeholder="Your message here"></textarea>
        </div>

        <div className="supportForm__bottom">
          <div onClick={() => navigate('/Support-feedback/tickets')} className="supportForm__close">
            <span className="supportForm__close-svg">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M9.47487 9.96458L11.9497 12.4395M11.9497 12.4395L14.4246 14.9143M11.9497 12.4395L14.4246 9.96458M11.9497 12.4395L9.47487 14.9143M21 12.4395C21 17.41 16.9706 21.4395 12 21.4395C7.02944 21.4395 3 17.41 3 12.4395C3 7.46889 7.02944 3.43945 12 3.43945C16.9706 3.43945 21 7.46889 21 12.4395Z"
                  stroke="black"
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div>close ticket</div>
          </div>
          <div className="supportForm__btnContainer">
            <AttachFileButton />
            <SubmitButton />
          </div>
        </div>
      </form>
    </div>
  );
};

export default SupportForm;
