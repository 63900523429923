import React, { useEffect, useState } from "react";
import "./style.scss";
import {
  fetchArticlesData,
  fetchCollectionsData,
} from "../../services/article-api";
import { Link } from "react-router-dom";

const AccordionMenu = () => {
  const [articles, setArticles] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const articlesData = await fetchArticlesData();
      const collectionsData = await fetchCollectionsData();

      setArticles(articlesData);
      setCollections(collectionsData);
    };

    fetchData();
  }, []);
  return (
    <div className="accordion-container">
      <div className="tabs">
        {collections.map((collection, index) => (
          <div className="tab" key={index}>
            <input className="input" type="checkbox" id={`chck${index + 1}`} />
            <label className="tab-label" htmlFor={`chck${index + 1}`}>
              {collection.name}
            </label>
            <div className="tab-content">
              <ul>
                {Object.entries(articles)
                  .filter(
                    (article) =>
                      article[1].parent_id &&
                      article[1].parent_id.toString() === collection.id
                  )
                  .map((article, index) => (
                    <li key={index}>
                      <Link
                        to={collection.id}
                        state={{ name: article[1].title }}
                      >
                        {article[1].title}
                      </Link>
                    </li>
                  ))}
                <a className="tab-content__more" href="#">
                  More
                </a>
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccordionMenu;
