import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import useFetchArticles from "../../hooks/useFetchArticles";

const Sidebar = () => {
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const collectionId = pathParts[pathParts.length - 1];
  const articles = useFetchArticles(collectionId);

  const handleItemClick = (id) => {
    const articleElement = document.getElementById(id);
    if (articleElement) {
      articleElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="sidebar-items">
      {articles.map((article) => (
        <div
          className="sidebar-item"
          key={article.title}
          onClick={() => handleItemClick(article.id)}
        >
          {article.title}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
