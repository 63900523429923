const baseHeaders = new Headers({
  accept: 'application/json',
  authorization: 'Bearer dG9rOmU0MmQzMGYxXzUzZTZfNDAxMV84MDliX2JjMjlmY2IxNjkwMDoxOjA=',
  'content-type': 'application/json',
  'Intercom-Version': '2.9',
});

export const getConversation = async (id) => {
  var myHeaders = new Headers(baseHeaders);

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  return fetch(`https://swgt.prospect.is/conversations/${id}`, requestOptions)
    .then((response) => response.json())
    .catch((error) => console.log('error', error));
};

export const sendConversationMessage = async (conversationId, message) => {
  var myHeaders = new Headers(baseHeaders);

  var raw = JSON.stringify({
    message_type: 'comment',
    type: 'user',
    body: message,
    intercom_user_id: '64660012397aba3e73052ae5',
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
  };

  fetch(`https://swgt.prospect.is/conversations/${conversationId}/reply`, requestOptions).catch(
    (error) => console.log('error', error),
  );
};
