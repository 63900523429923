import Button from "../Button";
import "./style.scss";

const Countdown = ({ upperText, bottomText }) => {
  const TimeElement = ({ value, unit }) => (
    <>
      <p className="semibold">{value}</p>
      <span>{unit}</span>
      <div className="countdown__break"></div>
    </>
  );
  return (
    <div className="countdown">
      <div className="countdown__wrapper">
        <h3 className="semibold">{upperText}</h3>
        <div className="countdown__time bodyUppercase">
          <TimeElement value="88" unit="Days" />
          <TimeElement value="6" unit="Hours" />
          <TimeElement value="59" unit="Mins" />
        </div>
      </div>
      <div className="countdown__join bodyUppercase">
        <span className="semibold">{bottomText}</span>
        <div className="countdown__join-mail">
          <div className="countdown__join-mail-input">
            <input type="text" placeholder="E-MAIL" />
          </div>
          <Button />
        </div>
      </div>
    </div>
  );
};

export default Countdown;
