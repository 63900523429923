import React, { useState, useEffect } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import ButtonSubmit from "../../components/ButtonSubmit";
import ButtonTickets from "../../components/ButtonTickets";
import AccordionMenu from "../../components/AccordionMenu";
import {
  fetchArticlesData,
  fetchCollectionsData,
} from "../../services/article-api";

export const MainPage = () => {
  const [articles, setArticles] = useState([]);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const articlesData = await fetchArticlesData();
      const collectionsData = await fetchCollectionsData();

      setArticles(articlesData);
      setCollections(collectionsData);
    };

    fetchData();
  }, []);

  return (
    <div className="main-container">
      <div className="main-tools-container">
        <div className="buttons-main__container">
          <ButtonSubmit />
          <ButtonTickets />
        </div>
        <div className="search-container">
          <div className="search-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5721 15.3912L18.9995 18.6496M17.0837 11.3934C17.0837 14.7303 14.3787 17.4353 11.0419 17.4353C7.70504 17.4353 5 14.7303 5 11.3934C5 8.0566 7.70504 5.35156 11.0419 5.35156C14.3787 5.35156 17.0837 8.0566 17.0837 11.3934Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <input
            type="text"
            className="search-input"
            placeholder="Enter here to search"
          />
        </div>
      </div>
      <AccordionMenu  />
      <div>
        <div className="articles__list">
          {collections.map((collection, index) => (
            <div className="articles__list-article article" key={index}>
              <h4 className="article-label">{collection.name}</h4>
              <div className="article-content">
                <ul>
                  {Object.entries(articles)
                    .filter(
                      (article) =>
                        article[1].parent_id &&
                        article[1].parent_id.toString() === collection.id
                    )
                    .map((article, index) => (
                      <li key={index}>
                        <Link
                          to={collection.id}
                          state={{ name: article[1].title }}
                        >
                          {article[1].title}
                        </Link>
                      </li>
                    ))}
                  <a className="article-content__more" href="src/pages/support/supportMainPage/main/index#">
                    More
                  </a>
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


